import { CreateImageDto } from "../fixsirat-admin-backend/src/images/dto/create-image.dto";

export enum Severity {
  Low = 1,
  Medium = 2,
  High = 3,
}
export enum MaterialType {
  A01 = "A01",
  A02 = "A02",
  A03 = "A03",
  B01 = "B01",
  B02 = "B02",
  B03 = "B03",
}

export enum IssueStatus {
  PENDING = "Pending",
  RESOLVED = "Resolved",
  CLOSED = "Closed",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  RETURNED = "Returned",
  REOPENED = "Reopened",
  REMOVED = "Removed",
}

export enum IssueStatusDetail {
  PENDING = "An approver will approve the issue for fixing soon.",
  APPROVED = "An admin will soon take action and resolve the issue",
  RESOLVED = "Issue is resolved",
  RETURNED = "Issue is returned to assignee",
  CLOSED = "Resolution is approved and issue is closed",
  REJECTED = "Issue is rejected as will not fix",
  REOPENED = "Resolution was not approved, it will be fixed again",
  REMOVED = "Issue removed, please create a new one",
}

export type ImageType = {
  dateTaken?: Date;
  imageLink: string;
  issueId?: string;
  isBefore?: boolean;
  isSelectedImage?: boolean;
  _id?: string;
};

export type IssueImages = {
  issueImages?: ImageType[];
};
export interface Issue extends IssueImages {
  _id?: string;
  category: string;
  header: string;
  severity: Severity;
  description?: string;
  issueStatus?: IssueStatus;
  assignee?: string;
  reporter: string;
}

export interface CommentType {
  comment?: string;
  reporter: string;
  userName: string;
  user?:{avatar?:string};
  issueId: string;
  audioLink?: string;
  createdAt?: string;
  taggedItems?: {
    id: string;
    name: string;
    value: string;
    type: string;
  }[];
}
export class UserDto {
  name!: string;
  email!: string;
  password!: string;
  role!: string | RoleDto;
}

export class RoleDto {
  name?: string;
  description?: string;
}

export type DashboardIssues = {
  recentIssues: Issue[];
  pendingIssues: Issue[];
  assignedIssues: Issue[];
  reportedbyMeIssues: Issue[];
  userResolvedIssues: number;
  userTotalIssues: number;
};
export type issuesGrouped = {
  Total: Issue[];
  Resolved: Issue[];
  Pending: Issue[];
};
export interface Notification {
  _id?: string;
  user: UserDto;
  publicKey: string;
  privateKey: string;
  endpoint: string;
  expirationTime?: string;
  p256dh?: string;
  auth?: string;
}
export enum NotificationsPermissionStatus {
  GRANTED = "granted",
  DENIED = "denied",
}
export enum UserPermissionType {
  CREATE_ISSUE = "create:issue",
  READ_ISSUE = "read:issue",
  UPDATE_ISSUE = "update:issue",
  DELETE_ISSUE = "delete:issue",
  UPDATE_STATUS_ISSUE = "updateStatus:issue",
  UPDATE_ASSIGNEE_ISSUE = "updateAssignee:issue",
  MANAGE_USERS = "manageUsers:admin",
}
export enum UserRole {
  SUPER_ADMIN = "SuperAdmin",
  DEFAULT_ADMIN = "DefaultAdmin",
  ADMIN = "Admin",
  OPERATION = "Operation",
  USER = "User",
  GUEST = "Guest",
}
export interface AuthUserAccessProps {
  roleTypes: [];
  permissions: [];
}

export interface Location {
  coordinates: [number, number];
  type: 'Point';
};
export interface AuthUser{
      user_id: string;
      email: string;
      avatar: string;
      name: string;
      blocked?:boolean;
}
export interface AuthRole {
  id: string;
  name: string;
  description: string;
  selected?: boolean;
}
export interface IssueSchemaData {
  title?: string;
  description?: string;
  type?: string | string[];
  default?: string | Severity | ImageType[] | Location | undefined;
  oneOf?: {const: string, title: string}[];
  "x-nfs-meta"?: { collection?: string; hidden?: boolean, editOnly?: boolean; component?: string, mappedToId?: boolean, filter?: boolean, mappedField?: string};
  minLength?: number,
  maxLength?: number,
  items?: object,
  properties?: {
    [key: string]: IssueSchemaData;
  };
  required?: string[];
}

export interface IssueSchema {
  "$id": string;
  title: string;
  description: string;
  type: string;
  required: string[];
  properties: IssueSchemaData;
}

export type UserInvite = {
  _id?: string;
  email: string;
  date?: string;
  inviteAccepted?: boolean;
};

export type User = {
  _id?: string;
  name: string;
  password: string;
  email: string;
  role: Role;
  avatar?: string;
  blocked?: boolean;
};

export interface Role {
  _id?: string;
  name?: string;
  description?: string;
}

export interface Permission {
  _id?: string;
  name?: string;
  description?: string;
  selected?: boolean;
}

export type RolePermission = {
  _id?: string;
  role?: Role;
  permission?: Permission;
};

export enum fieldType {
  STRING = "string",
  NUMBER = "number",
}
export type statusOfIssue = {
  resolved: number | undefined;
  total: number | undefined;
};

export enum NotificationMessageTypes {
  ISSUE_CREATION_TO_ADMIN = "issue_create_admin",
  ISSUE_UPDATION_TO_REPORTER = "issue_updation_reporter",
  ISSUE_UPDATION_TO_ASSIGNEE = "issue_updation_assignee",
  ISSUE_UPDATION_TO_SUBSCRIBER = "issue_updation_subscriber",
}
export type IssueStatusInvites = {
  _id?: string;
  email?: string;
  issueStatuses?: [
    {
      name: string;
      description?: string;
      selected?: boolean;
    }
  ];
};

export type NotificationSettings = {
  _id?: string;
  email?: string;
  createdAt?: string;
  issueStatuses?: string[];
  issueIds?: string[];
};

export type NotificationInformation = {
  fromEmail: string;
  toEmail: string;
  serverUrl?: string;
  issueId: string;
  issueStatus?: string;
  message?: string;
  title: string;
};

export interface NotificationDispatchInformation {
  notificationRecipients: NotificationRecipient[];
  issueId: string;
  fromEmail: string;
  title: string;
}

export enum RecipientTYPE {
  ADMIN = "ADMIN",
  REPORTER = "REPORTER",
  ASSIGNEE = "ASSIGNEE",
  SUBSCRIBER = "SUBSCRIBER",
  TAGGED = "TAGGED",
}

export interface NotificationRecipient {
  message: string;
  type: RecipientTYPE;
  serverUrl: string;
  email?: string;
}

export type AppSetting = {
  _id: string;
  name: string;
  value: string;
};

export type Category = {
  _id?: string;
  name: string;
  description: string;
};

export type CustomField = {
  _id: string;
  label: string;
  visible: boolean;
  fieldId: string;
  fieldType: string;
};

export type Activity = {
  issueId: string;
  activityId?:string;
  userId:string;
  activityType: string;
  metaData?: metaData;
};
export type StatusChange = {
  name?: string;
  color?: string;
}
export type metaData = {
  oldStatus?: StatusChange
  newStatus?:StatusChange
}
export interface cropperData {
  x: number;
  y: number;
  width: number;
  height: number;
  rotate: number;
  scaleX: number;
  scaleY: number;
}
